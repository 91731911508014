import { View } from "./View";
import { useSiteHeaderProps } from "~/components/common/SiteHeader/useProps";
import { useSiteMenuLinksProps } from "~/components/common/SiteMenuLinks/useProps";
import { useRootLoaderData } from "~/root.util";

export default function Route(): JSX.Element {
  const rootLoaderData = useRootLoaderData();
  return (
    <View
      siteHeaderProps={useSiteHeaderProps({
        isLogined: !!rootLoaderData.loginedUserId,
      })}
      siteMenuLinksProps={useSiteMenuLinksProps({
        selectedMenu: undefined,
        initialSelectedThemeId: rootLoaderData.themeId,
        isLogined: !!rootLoaderData.loginedUserId,
      })}
    />
  );
}

import { getSiteContactPagePath } from "../site.contact/path";
import { getHomePagePath } from "../_index/path";
import {
  SiteHeader,
  type SiteHeaderProps,
} from "~/components/common/SiteHeader";
import {
  SiteMenuLinks,
  type SiteMenuLinksProps,
} from "~/components/common/SiteMenuLinks";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";
import { layouts } from "~/components/layout";

export type ViewProps = {
  readonly siteHeaderProps: SiteHeaderProps;
  readonly siteMenuLinksProps: SiteMenuLinksProps;
};

// TODO: 整える
export function View({
  siteHeaderProps,
  siteMenuLinksProps,
}: ViewProps): JSX.Element {
  const dict = useDictionary();

  return (
    <div className="pb-20">
      <layouts.SiteHeaderSection>
        <SiteHeader {...siteHeaderProps} />

        <div className="mt-2 px-4">
          <SiteMenuLinks {...siteMenuLinksProps} />
        </div>
      </layouts.SiteHeaderSection>

      <layouts.CenteredPageBody className="mt-20 flex items-center justify-center">
        <div className="rounded-lg bg-white p-8 text-center shadow-lg">
          <h2 className="mb-4 text-4xl font-bold text-gray-800">
            404 Not Found
          </h2>
          <p className="mb-6 text-gray-600">
            {dict["404_the_page_you_are_looking_for_could_not_be_found"]}
          </p>
          <ul className="mb-6 list-inside list-disc text-left text-gray-700">
            <li>{dict["404_url_maybe_incorrect"]}</li>
            <li>{dict["404_page_may_have_been_removed"]}</li>
          </ul>

          <h3 className="mb-4 text-2xl font-bold text-gray-800">
            {dict["404_next_steps"]}
          </h3>

          <div className="space-y-4">
            <a
              href={getHomePagePath({})}
              className="block rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
            >
              {dict.go_to_home_page}
            </a>
            <a
              href={getSiteContactPagePath()}
              className="block rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
            >
              {dict.contact_us}
            </a>
          </div>
        </div>
      </layouts.CenteredPageBody>
    </div>
  );
}
